import {
  initSentryRoot,
  trackVueErrors,
} from "@solvari/common-fe/integrations";
import Vue from "vue";

initSentryRoot({
  dsn: import.meta.env.VITE_SENTRY_ADMIN_DSN as string,
  environment: import.meta.env.MODE,
  name: "admin",
  version: import.meta.env.npm_package_version as string,
});

trackVueErrors(Vue);
